import React from 'react'
import PropTypes from 'prop-types'
import { Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import Task from './task'

const Title = styled.h3`
  padding: 8px;
`

const ColumnContainer = styled.div`
margin: 8px;
border: 1px solid lightgrey;
border-radius: 2px;
width: 220px;
display: flex;
flex-direction: column
`

const TaskList = styled.div`
  padding: 8px;
  transition: background-color 0.2s ease;
  background-color: ${props => (props.isDraggingOver ? 'skyblue' : 'white')};
  flex-grow: 1;
  min-height: 100px;
  height: 80vh
`

Column.propTypes = {
  column: PropTypes.string,
  tasks: PropTypes.string
}

export default function Column ({ column, tasks }) {
  return (
    <ColumnContainer>
      <Title>{column.title}</Title>
      <Droppable droppableId={column.id}>
        {(provided, snapshot) => (
          <TaskList
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {/* todo: check if component should update for performance enhancement - video 13 as an example, it it needed with functional components? */}
            {tasks.map((task, index) => <Task key={task.id} task={task} index={index}></Task>)}
            {provided.placeholder}
          </TaskList>
        )}
      </Droppable>
      <input style={{ widht: '100%' }}></input>
    </ColumnContainer>
  )
}
