import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'twin.macro'
// eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from '../../styledComponents/misc/Headings.js'
import { SectionDescription } from '../../styledComponents/misc/Typography.js'
import SupportIconImage from 'images/support-icon.svg'
import ShieldIconImage from 'images/shield-icon.svg'
import CustomizeIconImage from 'images/customize-icon.svg'
import FastIconImage from 'images/fast-icon.svg'
import ReliableIconImage from 'images/reliable-icon.svg'
import SimpleIconImage from 'images/simple-icon.svg'

const Container = tw.div`
  relative 
  -mx-8 
  px-8 
  text-gray-100
`
const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`
const Heading = tw(SectionHeading)`w-full`
const Description = tw(SectionDescription)`w-full text-center text-gray-300`
const VerticalSpacer = tw.div`mt-10 w-full`

const CardTitle = styled.div`
  ${tw`tracking-wider font-bold text-xl leading-none  flex justify-center`}
`

const CardDescription = styled.div`
  ${tw`mt-2 font-normal text-gray-400 leading-snug flex justify-center`}
`

MarketingFeatures.propTypes = {
  cards: PropTypes.array,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string
}

export default function MarketingFeatures ({
  cards = null,
  heading = 'Amazing Features',
  subheading = '',
  description = 'Level up your teams and help foster a healthy collaboritive environment'
}) {
  const defaultCards = [
    {
      imageSrc: ShieldIconImage,
      title: 'Protected',
      description: 'Securely Hosted'
    },
    {
      imageSrc: SupportIconImage,
      title: '24/7 Support',
      description: '4-Hour First Response Time'
    },
    {
      imageSrc: CustomizeIconImage,
      title: 'Channels',
      description: 'Organize Groups'
    },
    {
      imageSrc: ReliableIconImage,
      title: 'Reliable',
      description: '99.99% Uptime SLA'
    },
    {
      imageSrc: FastIconImage,
      title: 'Insights',
      description: 'AI Powered Analytics'
    },
    {
      imageSrc: SimpleIconImage,
      title: 'Easy',
      description: 'Automatically Connect with Team Members'
    }
  ]

  if (!cards) cards = defaultCards

  return (
    <Container id='features' className='primary-secondardy-gradient-background'>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
          {cards.map((card, i) => (
            <div key={i} style={{ width: 300, height: 200, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
              <img className='as-img-round' src={card.imageSrc} alt="" />
              <CardTitle>{card.title}</CardTitle>
              <CardDescription>{card.description}</CardDescription>
            </div>
          ))}
        </div>
      </ThreeColumnContainer>
    </Container>
  )
}
