import React, { useState } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import Column from '../dnd/column'
import styled from 'styled-components'
import { Container as SemanticContainer } from 'semantic-ui-react'

const initialData = {
  tasks: {
    'task-1': { id: 'task-1', content: 'This is task 1' },
    'task-2': { id: 'task-2', content: 'This is task 2' },
    'task-3': { id: 'task-3', content: 'This is task 3' },
    'task-4': { id: 'task-4', content: 'This is task 4' }
  },
  columns: {
    'column-1': {
      id: 'column-1',
      title: 'Continue',
      taskIds: ['task-4', 'task-2', 'task-3', 'task-1']
    },
    'column-2': {
      id: 'column-2',
      title: 'Improve',
      taskIds: []
    },
    'column-3': {
      id: 'column-3',
      title: 'Stop',
      taskIds: []
    },
    'column-4': {
      id: 'column-4',
      title: 'Action Items',
      taskIds: []
    }
  },
  columnOrder: ['column-1', 'column-2', 'column-3', 'column-4']
}

const ColumnsContainer = styled.div`
  display: flex;
`

const RetroBoard = () => {
  const [tasks, setTasks] = useState(initialData.tasks)
  const [columns, setColumns] = useState(initialData.columns)
  const [columnOrder, setColumnOrder] = useState(initialData.columnOrder)

  const onDragEnd = ({ draggableId, type, reason, source, destination }) => {
    if (!destination) {
      return
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    const start = columns[source.droppableId]
    const finish = columns[destination.droppableId]

    if (start === finish) {
      const newTaskIds = [...start.taskIds]
      newTaskIds.splice(source.index, 1)
      newTaskIds.splice(destination.index, 0, draggableId)
      const newColumn = { ...start, taskIds: newTaskIds }

      setColumns({
        ...columns,
        [newColumn.id]: newColumn
      })

      return
    }

    // Moving form one list to another
    const startTaskIds = [...start.taskIds]
    startTaskIds.splice(source.index, 1)
    const newStart = {
      ...start,
      taskIds: startTaskIds
    }

    const finishTaskIds = [...finish.taskIds]
    finishTaskIds.splice(destination.index, 0, draggableId)
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds
    }

    setColumns({
      ...columns,
      [newStart.id]: newStart,
      [newFinish.id]: newFinish
    })

    // todo: persist in db
  }

  return (
    <SemanticContainer style={{ paddingTop: 40 }}>
      <h1>Retro</h1>
      <DragDropContext onDragEnd={onDragEnd}>
        <ColumnsContainer>
          { columns && columnOrder.map(columnId => {
            const column = columns[columnId]

            if (column) {
              const columnTasks = column.taskIds.map(taskId => tasks[taskId])

              return <Column key={column.id} column={column} tasks={columnTasks} />
            }
          })
          }
        </ColumnsContainer>
      </DragDropContext>
    </SemanticContainer>
  )
}

export default RetroBoard
