import React from 'react'
import Hero from './hero'
import About from './about'
import Features from './features'
import Pricing from './pricing'
import Blog from './blogs'
import Footer from './footer'

import 'tailwindcss/dist/base.css'
import '../../styles/globalStyles.css'
// import { css } from "styled-components/macro" //eslint-disable-line

const LandingPage = () => (
  <div style={{ marginTop: 30 }}>
    <Hero roundedHeaderButton={true} />
    <About />
    <Features />
    <Pricing />
    <Blog />
    <Footer />
  </div>
)

export default LandingPage
