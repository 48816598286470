import { LOGIN_USER, LOGOUT_USER } from '../actionTypes'

const initialState = JSON.parse(window.localStorage.getItem('user'))

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER: {
      const { firstName, lastName, email } = action.payload // todo: come up with user shema

      return { firstName, lastName, email }
    }
    case LOGOUT_USER: {
      return initialState
    }
    default:
      return state
  }
}
