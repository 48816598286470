import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './redux/store'
import App from './App'
import * as serviceWorker from './lib/serviceWorker'
// import 'semantic-ui-less/semantic.less'

import 'tailwindcss/dist/base.css'
import './styles/globalStyles.css'
import { css } from "styled-components/macro"; //eslint-disable-line

// eslint-disable-next-line no-unused-vars
import './styles/index.css'

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>, document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
