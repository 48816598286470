import React from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { getUser } from '../../redux/selectors'
import Dashboard from '../dashboard/dashboard'
import Login from '../login/login'
import SideBar from './sidebar/sidebar'
import Header from '../layout/header'
import Footer from '../layout/footer'
import RetroBoard from '../retroBoard/retroBoard'
import Kanban from '../kanban/kanban'
import LandingPage from '../landingPage/landingPage'

const Router = () => {
  const user = useSelector(getUser)

  return (
    <Switch>
      <Route path="/" exact>
        <LandingPage />
      </Route>
    </Switch>
  )

  // return (
  //   <Switch>

  //     <Route path="/" exact>
  //       <div style={{ display: 'flex' }}>
  //         <SideBar />
  //         <div className='an-grid'>
  //           <Header />
  //           <Dashboard />
  //           <Footer />
  //         </div>
  //       </div>
  //     </Route>
  //     <Route path="/myKanban">
  //       <div style={{ display: 'flex' }}>
  //         <SideBar />
  //         <div className='an-main'>
  //           <Kanban />
  //         </div>
  //       </div>
  //     </Route>
  //     <Route path="/retro">
  //       <div style={{ display: 'flex' }}>
  //         <SideBar />
  //         <div className='an-main'>
  //           <RetroBoard />
  //         </div>
  //       </div>
  //     </Route>
  //   </Switch>
  // )

  // todo: will fix once login is implemented

  if (user) {
    return (
      <Switch>
        <Route path="/">
          <Dashboard />
        </Route>
      </Switch>
    )
  }

  if (!user) {
    return (
      <Switch>
        <Route path="/">
          <Login />
        </Route>
      </Switch>
    )
  }
}

export default Router
