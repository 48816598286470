import { SET_THEME, TOGGLE_THEME_MODE } from '../actionTypes'

const initialState = {
  primaryColor: '', // todo pick a primary color
  secondaryColor: '', // todo pick a primary color
  mode: 'light'
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_THEME: {
      const { primaryColor, secondaryColor, mode } = action.payload

      return { primaryColor, secondaryColor, mode }
    }
    case TOGGLE_THEME_MODE: {
      // todo: change all themes to dark if light, light if dark  

      return {
        ...state,
      }
    }
    default:
      return state
  }
}
